import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/Layout"
import { Container } from "../components/Theme"

const Ethics = () => (
  <Layout>
    <Helmet
      title="Ethics - Metro Communications"
      meta={[
        { name: "description", content: `Our Ethics policy` },
        { name: "keywords", content: `` },
      ]}
    />
    <Container>
      <h1>Ethics</h1>
      <p>
        Rather than writing chapter and verse about modern day slavery and
        business ethics, a practice that risks hiding a million sins, Metro
        would prefer a more focused approach, albeit more revealing, about our
        ethical compass.
      </p>
      <h2>MODERN SLAVERY</h2>
      <p>
        Modern day slavery is typified by exploitative situations where a person
        cannot refuse or leave because of threats, violence, coercion, deception
        and/or abuse of power. It takes many forms and is unacceptable.
      </p>
      <p>
        Question: Where is Metro most likely to meet modern day slavery and how
        can we best manage it?
      </p>
      <p>
        Answer: Very infrequently, but sometimes, we have engaged the consulting
        services of IT consultants who offer their services via commercial
        websites. Some of these individuals are in developing countries. On the
        one hand it is good to work with these individuals as we get things done
        across different time zones and we are contributing to the development
        of their economy. On the other hand we can not validate that these
        individuals are free from modern day slavery. Moving forwards we will
        consulting with companies we know and trust and get their
        recommendations as that gives us more than one reference point.
      </p>
      <h2>PAY ON TIME</h2>
      <p>
        Some customers exploitatively pay their suppliers late, if at all. This
        causes tremendous trauma is an abusive, if not criminal, practice and
        can destry businesses and lives.
      </p>
      <p>Question: Where is Metro's position and how can we best manage it?</p>
      <p>Answer: To pay our invoices on time, all the time. Specifically :</p>
      <ul>
        <li>
          To agree payment terms at the outset of a deal and stick to them
        </li>
        <li>To explain our payment procedures to suppliers</li>
        <li>
          To pay bills in accordance with any contract agreed with suppliers or
          as required by law
        </li>
        <li>
          To tell suppliers without delay when an invoice is contested, and
          settle disputes quickly
        </li>
        <li>
          Where we need to provide refunds to do so quickly and explain to
          customers why there might be delays of variances e.g. exchange rate
          fluctuations.
        </li>
      </ul>
      <p>
        We will also avoid doing business with companies and companies whose
        Directors have a track record or reputation for such practices.
      </p>
    </Container>
  </Layout>
)

export default Ethics
